/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/* jshint latedef:nofunc */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var eClass = {
    // All pages
    common: {
      init: function() {
        // Horizontal accordion
        $.fn.horzAccordion = function(options) {
          var settings = $.extend(
            {
              elementSelector: '.item',
              elementChild: '.item .cover',
              onOpen: function($el) {},
              onClose: function($el) {}
            },
            options
          );
          var $elements = this.find(settings.elementSelector);
          var $elementsChild = this.find(settings.elementChild);
          var $mainEl = this;

          this.css('position', 'relative');
          this.css('overflow', 'hidden');
          this.removeClass('invisible');
          $elements.css('position', 'absolute');
          $mainEl.css('height', $($elements).height());

          for (var i = 0; i < $elements.length; i++) {
            $(
              '<span class="accordion-close px-2 ease h3 text-white z-4"><i class="icon-close"></i></span>'
            ).appendTo($elements[i]);
            $($elements[i]).css('left', i * (100 / $elements.length) + '%');
            $($elementsChild).css('width', 100 / $elements.length + '%');
            $($elements[i]).click(function(e) {
              $(this)
                .nextAll()
                .removeClass('active');
              $(this)
                .prevAll()
                .removeClass('active');
              $(this).addClass('active');
              $(this).animate(
                {
                  left: 0
                },
                700
              );
              $(this)
                .nextAll()
                .animate(
                  {
                    left: '100%'
                  },
                  700
                );
              settings.onOpen($(this));
              return false;
            });

            $($elements[i])
              .find('.accordion-close')
              .click(function(e) {
                var $curEl = $(this).closest(settings.elementSelector);
                if ($curEl.hasClass('active')) {
                  var index = $mainEl
                    .find(settings.elementSelector)
                    .index($curEl);
                  $curEl.removeClass('active').animate(
                    {
                      left:
                        index *
                          (100 /
                            $mainEl.find(settings.elementSelector).length) +
                        '%'
                    },
                    500
                  );
                  settings.onClose($curEl);

                  $curEl.nextAll().each(function(id, el) {
                    var index = $mainEl
                      .find(settings.elementSelector)
                      .index($(el));
                    $(el).animate(
                      {
                        left:
                          index *
                            (100 /
                              $mainEl.find(settings.elementSelector).length) +
                          '%'
                      },
                      500
                    );
                  });
                }
                e.stopPropagation();
                return false;
              });
          }
        };

        $('.modal-forms').on('hidden.bs.modal', function(e) {
          $('.diplocursos .modal-script-class script').each((_, node) =>
            eval($(node).html())
          );
        });

        $('.accordion-h').horzAccordion();

        // Mmenu
        var mmenu = $('#mmenu')
          .mmenu({
            extensions: ['pagedim-black', 'position-front', 'position-right']
          })
          .data('mmenu');

        var $icon = $('.toggle-menu');

        $icon.click(function(ev) {
          ev.preventDefault();
          if ($('html').hasClass('mm-opened')) {
            mmenu.close();
            setTimeout(function() {
              $icon.removeClass('is-active');
            }, 100);
          } else {
            mmenu.open();
            setTimeout(function() {
              $icon.addClass('is-active');
            }, 100);
          }
        });

        // Hover acceso alumnos
        $('.jsAcceso').on('mouseover touchstart click', function() {
          $(this)
            .parents('.top')
            .addClass('active');
        });
        $('.jsAcceso').on('mouseout touchend', function() {
          $(this)
            .parents('.top')
            .removeClass('active');
        });

        // Ease scroll function
        function easeScroll(size) {
          $('.ease-scroll').on('click', function(e) {
            e.preventDefault();
            var target = this.hash;
            var $target = $(target);
            $('html, body')
              .stop()
              .animate(
                {
                  scrollTop: $target.offset().top - size
                },
                900,
                'swing',
                function() {
                  // window.location.hash = target;
                }
              );
            $('.navbar-toggle').click();
            return false;
          });
        }
        easeScroll(0);

        // Convert primary submenu to  colums
        (function() {
          const $subMenu = $('.submenu');
          const $subNm = $subMenu.each(function() {
            const $subItem = $(this).find('.dropdown-item');
            for (var i = 0; i < $subItem.length; i += 3) {
              $subItem
                .slice(i, i + 3)
                .wrapAll("<div class='d-flex flex-nowrap'></div>");
            }
            return this;
          });
        })();

        // On load global modal

        $('#modalGlobal').on('show.bs.modal', function(e) {
          $icon.removeClass('is-active');
          mmenu.close();
        });

        // Custom select
        function customSelect() {
          $('.select').dropkick({
            mobile: true
          });
        }

        // Fade home video
        function homeVideo() {
          var video = $('.hero video');
          $(video)
            .delay(300)
            .fadeIn();
        }

        // Collapse niveles
        const $cardLink = $('.card-link_ease');
        const $cardLinkTab = $('.card-link_tab');
        const $cardDrop = $('.card-link_dropdown');
        const $closeCollapse = $('.closeCollapse');
        const $carouselLevels = $('.carouselLevels');
        const $collapseContent = $('#collapseContent');
        const $tabContent = $('.tabContent');

        $cardLinkTab.on('click', function(e) {
          if ($(this).attr('aria-expanded') === 'true') {
            return false;
          }
          if (window.innerWidth <= 768) {
            goToContent($tabContent);
          }
        });

        $cardLink.on('click', function(e) {
          const $dataID = $(this).data('id');
          const $carouselChild = $(this);

          if (!$carouselLevels.hasClass('slick-initialized')) {
            carouselLevels();
            $carouselLevels.slick('slickGoTo', $dataID - 1);
            onChangeCarouselLevels();
            goToContent($('.js-collapse-carousel-target'));
          } else {
            $carouselLevels.slick('slickGoTo', $dataID - 1);
            onChangeCarouselLevels();
            goToContent($('.js-collapse-carousel-target'));
            return false;
          }
        });

        if ($carouselLevels.hasClass('auto-init')) {
          carouselLevels();
        } else {
        }

        // Carousel levels
        function carouselLevels() {
          $carouselLevels.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            autoplay: false,
            prevArrow:
              '<div class="prev icon-angle-left display-4 abs-center-y"></div>',
            nextArrow:
              '<div class="next hoverCollapse icon-angle-right display-4 abs-center-y"></div>'
          });
        }

        function onChangeCarouselLevels() {
          $carouselLevels.on('afterChange', function(
            event,
            slick,
            currentSlide
          ) {
            const $currentSlide = currentSlide + 1;
            const $dataIDItem = $('[data-id=' + $currentSlide + ']');
            $cardDrop.removeClass('active');
            $dataIDItem.addClass('active');
          });
        }

        function goToContent(collapseContent) {
          if ($cardDrop.hasClass('js-scroll-content')) {
            $('html, body').animate(
              {
                scrollTop: collapseContent.offset().top - 18
              },
              1000
            );
          } else {
            if (window.innerWidth <= 768) {
              if ($cardDrop.hasClass('js-scroll-content-mobile')) {
                $('html, body').animate(
                  {
                    scrollTop: collapseContent.offset().top + 1
                  },
                  1000
                );
              } else {
              }
            }
          }
        }

        $closeCollapse.on('click', function() {
          $('.collapse-carousel').collapse('hide');
          $carouselLevels.slick('unslick');
          $cardDrop.removeClass('active');
          if ($('.js-scroll-content').length) {
            $('html, body').animate(
              { scrollTop: $('.js-scroll-content').offset().top - 25 },
              500
            );
          }

          return false;
        });

        // Mobile carousel
        function carouselMobile() {
          $('.carouselMobile').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            autoplay: true
          });
        }

        function carouselMobileDestroy() {
          if ($('.carouselMobile').hasClass('slick-initialized')) {
            $('.carouselMobile').slick('unslick');
          }
        }

        // Carousel imagenes
        const $icarousel = $('.il-carousel');

        $icarousel.slick({
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 1000,
          slidesToShow: 7,
          slidesToScroll: 7,
          prevArrow:
            '<div class="prev icon-angle-left display-4 abs-center-y"></div>',
          nextArrow:
            '<div class="next hoverCollapse icon-angle-right display-4 abs-center-y"></div>',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
        });

        // Detect mobile
        var isMobile = {
          Android: function() {
            return navigator.userAgent.match(/Android/i);
          },
          BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
          },
          iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
          },
          Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
          },
          Windows: function() {
            return (
              navigator.userAgent.match(/IEMobile/i) ||
              navigator.userAgent.match(/WPDesktop/i)
            );
          },
          any: function() {
            return (
              isMobile.Android() ||
              isMobile.BlackBerry() ||
              isMobile.iOS() ||
              isMobile.Opera() ||
              isMobile.Windows()
            );
          }
        };

        // Mobile device functions
        function mobile(e) {
          $('body').addClass('mobile');
        }

        // Not mobile device functions
        function not_mobile(e) {
          // $('body').addClass('desktop');
          // console.log("no soy mobile");
          homeVideo();
        }

        // Small device functions
        function smallDevice(e) {
          carouselMobile();
          // console.log("tablet");
        }

        // Large device functions
        function largeDevice(e) {
          carouselMobileDestroy();
          // console.log("desktop");
        }

        // Target mobile devices
        if (isMobile.any()) {
          mobile();
        } else {
          not_mobile();
        }

        // To media queries
        (function(window, document, undefined) {
          'use strict';
          var mediaQuery = window.matchMedia('(max-width: 767px)');

          function doSomething(mediaQuery) {
            if (mediaQuery.matches) {
              smallDevice();
            } else {
              largeDevice();
            }
          }
          doSomething(mediaQuery);
          mediaQuery.addListener(doSomething);
        })(window, document);

        // Animate title
        $(function() {
          var controller = new ScrollMagic.Controller();

          $('.jsTriggerTitle').each(function(index, elem) {
            new ScrollMagic.Scene({
              triggerElement: elem,
              triggerHook: 'onEnter',
              offset: 150,
              reverse: false
            })

              .on('start', function() {
                $(elem).addClass('active');
                var preloaderOutTl = new TimelineMax();
                preloaderOutTl.addLabel('targetPoint');
                preloaderOutTl.add(
                  TweenLite.fromTo(
                    elem,
                    1,
                    {
                      y: 30,
                      opacity: 0
                    },
                    {
                      y: 0,
                      opacity: 1
                    }
                  ),
                  'targetPoint'
                );
                preloaderOutTl.add(
                  TweenLite.fromTo(
                    '.jsTriggerTitle1',
                    1,
                    {
                      y: 30,
                      opacity: 0
                    },
                    {
                      y: 0,
                      opacity: 1
                    }
                  ),
                  'targetPoint+=0.5'
                );
                preloaderOutTl.add(
                  TweenLite.fromTo(
                    '.jsTriggerTitle2',
                    1,
                    {
                      y: 30,
                      opacity: 0
                    },
                    {
                      y: 0,
                      opacity: 1
                    }
                  ),
                  'targetPoint+=0.9'
                );
                return preloaderOutTl;
              })
              // .addIndicators()
              .reverse(false)
              .addTo(controller);
          });
        });

        // Animate imagen column
        $(function() {
          var controller = new ScrollMagic.Controller();

          $('.img-carousel').each(function(index, elem) {
            new ScrollMagic.Scene({
              triggerElement: elem,
              triggerHook: 'onEnter',
              offset: 150,
              reverse: false
            })

              .on('start', function() {
                $(elem).addClass('active');
                var preloaderOutTl = new TimelineMax();
                preloaderOutTl.addLabel('targetPoint');
                preloaderOutTl.add(
                  TweenLite.fromTo(
                    elem,
                    1,
                    {
                      x: 30,
                      opacity: 0
                    },
                    {
                      x: 0,
                      opacity: 1
                    }
                  ),
                  'targetPoint'
                );
                return preloaderOutTl;
              })
              .addTo(controller);
            // .addIndicators();
          });
        });

        // steps
        $(function() {
          var controller = new ScrollMagic.Controller();

          var scene = new ScrollMagic.Scene({
            triggerElement: '.stagger',
            reverse: false,
            offset: -150
          })
            .on('start', function() {
              $('.stagger .item').removeClass('invisible');

              var preloaderOutTl = new TimelineMax();
              preloaderOutTl.addLabel('targetPoint');

              TweenMax.staggerFrom(
                '.stagger .item',
                2,
                {
                  opacity: 0,
                  y: 30,
                  ease: Back.easeOut,
                  delay: 0.4
                },
                0.6
              );

              return preloaderOutTl;
            })
            // .addIndicators({ name: "trigger stagger", colorEnd: "#FFFFFF" })
            .addTo(controller);
        });

        // Sticky top on scroll
        $(function() {
          var controller = new ScrollMagic.Controller();

          $('.stickScroll').each(function(index, elem) {
            new ScrollMagic.Scene({
              triggerElement: elem,
              triggerHook: 'onLeave'
            })

              .setPin(elem)
              .addTo(controller);
          });
        });

        // Carousel
        const $singleCarousel = $('.singleCarousel');

        $singleCarousel.slick({
          infinite: true,
          arrows: false,
          dots: false,
          autoplay: true,
          autoplaySpeed: 3000,
          slidesToShow: 1,
          slidesToScroll: 1
        });

        // Carousel Profesores

        const $profesoresCarousel = $('.profesoresCarousel');
        const $profesoresCarouselItem = $('.profesoresCarousel .item');

        function carouselProfesores() {
          $profesoresCarousel.slick({
            dots: false,
            infinite: true,
            autoplay: false,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            prevArrow:
              '<div class="prev icon-angle-left display-4 abs-center-y"></div>',
            nextArrow:
              '<div class="next hoverCollapse icon-angle-right display-4 abs-center-y"></div>',
            responsive: [
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 769,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 577,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });
        }

        (function(window, document, undefined) {
          'use strict';
          var mediaQuery = window.matchMedia('(max-width: 767px)');

          function profesResponsive(mediaQuery) {
            if (mediaQuery.matches) {
              carouselProfesores();
            } else {
              if ($profesoresCarouselItem.length > 4) {
                carouselProfesores();
              } else {
              }
            }
          }
          profesResponsive(mediaQuery);
          mediaQuery.addListener(profesResponsive);
        })(window, document);

        // Select categorias cursos y diplomados
        // Id wordpress sites
        // 1 Global
        // 2 Chile
        // 3 Peru
        // 4 Colombia

        // Id Portales
        // 6 Web Retail Chile
        // 8 Web Retail Perú
        // 9 Web Retail Colombia
        //10 Web Retail Latam

        function urlBase(a) {
          if (a === 1) {
            return window.location.origin + '/wp-json/wp/v2/';
          } else if (a === 2) {
            return window.location.origin + '/cl/wp-json/wp/v2/';
          } else if (a === 3) {
            return window.location.origin + '/pe/wp-json/wp/v2/';
          } else if (a === 4) {
            return window.location.origin + '/co/wp-json/wp/v2/';
          } else {
            return window.location.origin + '/wp-json/wp/v2/';
          }
        }

        function locationUrl(b) {
          return urlBase($idWp) + b;
        }

        // Get wp site id
        var $idWp = $('.country').data('country'); // id de wordpress site
        var $idIns = $('.api-id').data('api-id'); // id de institucion en api de eclass
        var $idInsWp = $('.wp-id').data('wp-id'); // id de institucion en wordpress
        var $typeContent = $('.type').data('type'); // tipo de contenido
        var $typeList = $('.list-type').data('list'); // tipo de contenido
        var $typeCategoria = $('.list-category').data('category'); // tipo de categoria
        var $areas = $('.areas');
        var $location = locationUrl($typeContent);
        var $urlBase = urlBase($idWp);

        function loadData(url) {
          var tmp = null;
          $.ajax({
            async: false,
            dataType: 'json',
            url: url,
            success: function(data) {
              $areas.find('.loader').remove();
              // console.log(data);
              tmp = data;
            },
            error: function(errorThrown) {
              console.log(errorThrown);
            }
          });
          return tmp;
        }

        function loadDataAsync(url, page) {
          // console.log('Entro en loadDataAsync');
          // console.log('url:', url);

          if (!page) {
            page = 1;
          }

          url = url.replace(/&page=([0-9]+)/g, '&page=' + page)
          var content = [];
          $.ajax({
            dataType: 'json',
            url: url,
            success: function(data) {
              // console.log('loadDataAsync data:', data);
              $areas.find('.loader').remove();
              content = data;
              // console.log('content sin filtro:', content);
              content = content.filter(function(inst) {
                // console.log('inst:', inst);
                // console.log('$idInsWp:', $idInsWp);
                return $idIns !== 0 ? inst.parent_id === $idInsWp : ($idIns === 0 ? true : false);
              });
              // console.log('content con filtro:', content);
              if ($typeList === 'yes') {
                cat = {};
                content.forEach(function(item) {
                  // console.log('item id: ', item.id);
                  if (item.hasOwnProperty('acf') && item.acf.categorias.length) {
                    // console.log('item.acf.categorias:', item.acf.categorias);
                    item.acf.categorias.forEach(function(categoria) {
                      if (cat[categoria.id]) {
                        cat[categoria.id].posts.push({
                          link: item.link,
                          title: item.title.rendered
                        });
                      } else {
                        cat[categoria.id] = {
                          posts: [
                            {
                              link: item.link,
                              title: item.title.rendered
                            }
                          ],
                          categoria: categoria
                        };
                      }
                    });
                  }
                });

                const map = new Map(Object.entries(cat));

                // Agrega categoría.
                map.forEach(function(e) {
                  if ($areas.find('[data-category="'+e.categoria.id+'"]').length) {
                    $areas.find('[data-category="'+e.categoria.id+'"]').append(e.posts.reduce(function(prev, cat, i) {
                      return (
                        prev +
                        ('<option data-link="' +
                          cat.link +
                          '" value="' +
                          i +
                          '"> ' +
                          cat.title +
                          '</option>')
                      );
                    }, []));
                  }
                  else {
                    $areas.append(
                      '<div class="col-sm-4 mb-sm-6 mb-3 mb-sm-0"> <select class="select w-100 cat-select" data-category="' + e.categoria.id + '">' +
                        '<option value="' +
                        e.categoria.id +
                        '"> ' +
                        e.categoria.nombre +
                        '</option>' +
                        e.posts.reduce(function(prev, cat, i) {
                          return (
                            prev +
                            ('<option data-link="' +
                              cat.link +
                              '" value="' +
                              i +
                              '"> ' +
                              cat.title +
                              '</option>')
                          );
                        }, []) +
                        '</select> </div>'
                    );

                  }
                  
                });
                
              } else {
                content.forEach(function(data) {
                  $areas.append(
                    '<div class="col-sm-6 col-md-4 mb-sm-6 mb-3 mb-sm-0"> ' +
                      '<a href="' +
                      data.link +
                      '" class="dk-selected dk-link w-100 px-3 pr-6 py-3 font-base-color offer-' + data.id + '">' +
                      '<span data-id="' +
                      data.id +
                      '"> ' +
                      data.title.rendered +
                      '</span>' +
                      '</a>' +
                      '</div> '
                  );
                });
              }

              // Categorías recursivo
              if (!data.code) {
                loadDataAsync(url, page+1);
              }
            },
            error: function(errorThrown) {
              customSelect();
              console.log(errorThrown);
            }
          });
        }

        function getCategorias() {
          if ($('.list-category').length > 0) {
            if ($typeCategoria === 'diplomados') {
              var dataDiplomados = $urlBase + 'diplomados?_fields=id,title,link,acf.categorias,parent_id&per_page=100&page=1';
              loadDataAsync(dataDiplomados);
            } else if ($typeCategoria === 'cursos') {
              var dataCursos = $urlBase + 'cursos?_fields=id,title,link,acf.categorias,parent_id&per_page=100&page=1';
              loadDataAsync(dataCursos);
            } else if ($typeCategoria === 'no') {
              $areas.find('.loader').remove();
            }
          } else {
            loadDataAsync($location + '?_fields=id,title,link,acf.categorias,parent_id&per_page=100&page=1');
          }
        }

        $(document).on('change', '.cat-select', function() {
          var link = $(this)
            .find(':selected')
            .data('link');
          window.location = link;
        });

        if ($areas.length > 0) {
          getCategorias();
        }

        // Keep focus state if hubspot form is filled
        function hsField() {
          window.onload = function() {
            $('.form-hubspot .hs-input').blur(function() {
              if (!$(this).val()) {
              } else {
                $(this)
                  .closest('.hs-form-field')
                  .addClass('has-value');
              }
            });

            $('.form-hubspot .hs-input').on(
              'change paste keyup touchstart click',
              function() {
                if (!$(this).val()) {
                  $(this)
                    .closest('.hs-form-field')
                    .removeClass('has-value');
                }
              }
            );
          };
        }

        var $fh = $('.form-hubspot');

        if ($fh.length > 0) {
          hsField();
        }

        // Search cursos and diplomados
        function urlPathSearch(a) {
          if (a.indexOf('/cl/') >= 0) {
            return '/cl/wp-json/api/search?';
          } else if (a.indexOf('/pe/') >= 0) {
            return '/pe/wp-json/api/search?';
          } else if (a.indexOf('/co/') >= 0) {
            return '/co/wp-json/api/search?';
          } else {
            return '/wp-json/api/search?';
          }
        }

        var $searchBar = $('.search-bar');
        var $searchInput = $('.search-input');
        var $searchInputGlobal = $('.search-input-global');
        var $searchResults = $('.search-results');
        var $urlPath = window.location.pathname;
        var $searchUrl = window.location.origin + urlPathSearch($urlPath);

        function clearSearch() {
          $searchResults.slideUp('fast');
          $searchBar.removeClass('loading loaded no-results');
          setTimeout(function() {
            $searchResults.html('');
          }, 500);
        }

        function searchType(a, text) {
          if (a === 'cursos') {
            return $searchUrl + 'diplomados=false&texto=' + text;
          } else if (a === 'diplomados') {
            return $searchUrl + 'cursos=false&texto=' + text;
          } else {
            return $searchUrl + 'diplomados=true&cursos=true&texto=' + text;
          }
        }

        function loadjsonSearch(url, text) {
          $searchBar.addClass('loading').removeClass('loaded');
          $.ajax({
            cache: false,
            url: url,
            type: 'get',
            dataType: 'json',
            success: function(response) {
              $searchBar.removeClass('loading').addClass('loaded');
              if (!$.isArray(response) || !response.length) {
                $searchBar.removeClass('loaded loading');
                $searchBar.addClass('no-results');
                $searchResults
                  .slideDown('fast')
                  .html(
                    '<li><span class="name d-block py-2">Sin resultados</span></li>'
                  );
              } else {
                $searchResults.html(
                  response.map(function(dato) {
                    var result = '';
                    if (dato.tipo === 'Clase') {
                      result =
                        ' ' +
                        '<li>' +
                        '<a href="' +
                        dato.link +
                        '" class="d-block">' +
                        '<span class="name d-block">' +
                        dato.nombreCurso +
                        '</span>' +
                        '<span class="int d-block">' +
                        highlight(dato.nombre, text) +
                        '</span>' +
                        '<span class="int d-block">' +
                        highlight(dato.institucion, text) +
                        '</span>' +
                        '</a>' +
                        '</li>' +
                        '';
                    } else {
                      result =
                        ' ' +
                        '<li>' +
                        '<a href="' +
                        dato.link +
                        '" class="d-block prelo-medium-italic text-gray">' +
                        '<span class="name d-block">' +
                        highlight(dato.nombre, text) +
                        '</span>' +
                        '<span class="int d-block">' +
                        highlight(dato.institucion, text) +
                        '</span>' +
                        '</a>' +
                        '</li>' +
                        '';
                    }

                    return result;
                  })
                );
                setTimeout(function() {
                  $searchResults.slideDown('fast');
                }, 100);
              }
            },
            error: function(ms, textStatus, errorThrown) {
              console.log(errorThrown);
            }
          });
        }

        function highlight(a, text) {
          var value;
          var regexStr = text
            .split(' ')
            .map(val => {
              return `(${val})`;
            })
            .join('|');

          var re = new RegExp(regexStr, 'ig');
          if (a.search(re) >= 0) {
            value = a.replace(re, function(a) {
              return "<span class='highlight'>" + a + '</span>';
            });
          } else {
            value = a
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(re, function(a) {
                return "<span class='highlight'>" + a + '</span>';
              });
          }
          return value;
        }

        var timer;
        var timeout = 300;

        function getSearchContent(target, type, institucion) {
          var $text = target.value;
          var $searchType = searchType(type, $text);
          var $searchUrlJson = function(inst) {
            if (institucion === 0) {
              return $searchType;
            } else {
              return $searchType + '&institucion=' + institucion;
            }
          };

          clearTimeout(timer);
          if (target.value.length > 2) {
            timer = setTimeout(function() {
              loadjsonSearch($searchUrlJson(institucion), $text);
            }, timeout);
          } else {
            clearSearch();
          }
        }

        $searchInput.bind('keyup', function(e) {
          getSearchContent(this, $typeContent, $idInsWp);
        });

        $searchInputGlobal.bind('keyup', function(e) {
          getSearchContent(this, 'global', 0);
        });

        $('.search-bar .icon-close').on('click', function() {
          setTimeout(function() {
            clearSearch();
            $searchInput.val('');
          }, 200);
          return false;
        });

        $(document).on('click', function(event) {
          if (!$(event.target).closest('.search-bar-wrapper').length) {
            clearSearch();
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
        // When the DOM is ready

        // Video autoplay home
        setTimeout(function() {
          document.getElementById('vid').play();
        }, 500);

        var heroHeight = $('.hero').height();
        var heroHalf = heroHeight / 2;

        // smooth fade hero content
        $(function() {
          var tween = new TimelineMax();
          var scrollMagicController = new ScrollMagic.Controller();

          tween.fromTo(
            '.hero .header-content',
            0.6,
            {
              opacity: '1',
              y: '0',
              ease: Power0.easeNone,
              delay: 1
            },
            {
              opacity: '0',
              y: '100px',
              ease: Power0.easeNone
            }
          );

          var scene = new ScrollMagic.Scene({
            triggerElement: '.hero',
            triggerHook: 'onLeave',
            duration: heroHalf,
            offset: heroHalf / 2
          })
            .setTween(tween)
            .addTo(scrollMagicController);
          // scene.addIndicators({name: "hero content"});
        });

        // Hero home parallax
        $(function() {
          var tween = new TimelineMax();
          var scrollMagicController = new ScrollMagic.Controller();

          tween.to('.hero__home .video', 0.6, {
            opacity: '0.3',
            y: '50%',
            ease: Power0.easeNone
          });

          var scene = new ScrollMagic.Scene({
            triggerElement: '.hero__home',
            triggerHook: 'onLeave',
            duration: heroHeight
          })
            .setTween(tween)
            .addTo(scrollMagicController);
          // scene.addIndicators({name: "hero parallax"});
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    sobre_eclass: {
      init: function() {
        /*
         **	Plugin for counter shortcode
         */
        (function($) {
          'use strict';

          $.fn.countTo = function(options) {
            // merge the default plugin settings with the custom options
            options = $.extend({}, $.fn.countTo.defaults, options || {});

            // how many times to update the value, and how much to increment the value on each update
            var loops = Math.ceil(options.speed / options.refreshInterval),
              increment = (options.to - options.from) / loops;

            return $(this).each(function() {
              var _this = this,
                loopCount = 0,
                value = options.from,
                interval = setInterval(updateTimer, options.refreshInterval);

              function updateTimer() {
                value += increment;
                loopCount++;
                $(_this).html(value.toFixed(options.decimals));

                if (typeof options.onUpdate === 'function') {
                  options.onUpdate.call(_this, value);
                }

                if (loopCount >= loops) {
                  clearInterval(interval);
                  value = options.to;

                  if (typeof options.onComplete === 'function') {
                    options.onComplete.call(_this, value);
                  }
                }
              }
            });
          };

          $.fn.countTo.defaults = {
            from: 0,
            to: 100,
            speed: 1000,
            refreshInterval: 100,
            decimals: 0,
            onUpdate: null,
            onComplete: null
          }; // the number the element should start at // the number the element should end at // how long it should take to count between the target numbers // how often the element should be updated // the number of decimal places to show // callback method for every time the element is updated, // callback method for when the element finishes updating
        })(jQuery);

        // Animate imagen column
        $(function() {
          var controller = new ScrollMagic.Controller();

          $('.counter').each(function(index, elem) {
            var $this = $(this);

            new ScrollMagic.Scene({
              triggerElement: elem,
              // triggerHook: 'onEnter',
              offset: -200,
              reverse: false
            })

              .on('start', function() {
                $this.fadeTo('fast', 1);
                $this.countTo({
                  from: 0,
                  to: $this.attr('data-count'),
                  speed: 1500,
                  refreshInterval: 100
                });
              })
              // .addIndicators()
              .addTo(controller);
          });
        });

        // JavaScript Document
        (function($) {
          function render_map($el) {
            // var
            var $markers = $el.find('.marker');
            // vars
            var args = {
              zoom: 16,
              center: new google.maps.LatLng(0, 0),
              mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            // create map
            var map = new google.maps.Map($el[0], args);
            // add a markers reference
            map.markers = [];

            function add_marker($marker, map) {
              // var
              var latlng = new google.maps.LatLng(
                $marker.attr('data-lat'),
                $marker.attr('data-lng')
              );
              // create marker
              var marker = new google.maps.Marker({
                position: latlng,
                map: map
              });
              // add to array
              map.markers.push(marker);
              // if marker contains HTML, add it to an infoWindow
              if ($marker.html()) {
                // create info window
                var infowindow = new google.maps.InfoWindow({
                  content: $marker.html()
                });
                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function() {
                  infowindow.open(map, marker);
                });
              }
            }

            // add markers
            $markers.each(function() {
              add_marker($(this), map);
            });

            function center_map(map) {
              // vars
              var bounds = new google.maps.LatLngBounds();
              // loop through all markers and create bounds
              $.each(map.markers, function(i, marker) {
                var latlng = new google.maps.LatLng(
                  marker.position.lat(),
                  marker.position.lng()
                );
                bounds.extend(latlng);
              });
              // only 1 marker?
              if (map.markers.length === 1) {
                // set center of map
                map.setCenter(bounds.getCenter());
                map.setZoom(16);
              } else {
                // fit to bounds
                map.fitBounds(bounds);
              }
            }

            // center map
            center_map(map);
            // Tabs mapa go to
            $('.tabs-mapas a[data-toggle="tab"]').on('shown.bs.tab', function(
              e
            ) {
              $('html, body').animate(
                {
                  scrollTop: $($.attr(this, 'href')).offset().top - 25
                },
                500
              );

              google.maps.event.trigger(map, 'resize');
              center_map(map);
            });
          }

          $('.acf-map').each(function() {
            render_map($(this));
          });
        })(jQuery);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = eClass;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS

      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
