jQuery(document).ready(function($) {
  var $submit = $('#publishing-action input[type="submit"]');
  var $selectParent = $('.js-requiredMeta');
  var $body = $('body');

  if (
    $body.hasClass('post-type-cursos') ||
    $body.hasClass('post-type-diplomados')
  ) {
    $('#institucion-parent h2 span').append(
      '<span class="acf-required">*</span>'
    );

    (function() {
      if ($('.js-requiredMeta option:selected').val() === '-1') {
        $submit.addClass('disabled');
      } else {
        $submit.removeClass('disabled');
      }
    })();

    $selectParent.on('change', function() {
      if (this.value === '-1') {
        $submit.addClass('disabled');
      } else {
        $submit.removeClass('disabled');
      }
    });
  }
});
